import * as React from 'react';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import skillData from '../data/skills';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import IndividualSkill from './partials/skill';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
const Skills = () => {
  const [value, setValue] = React.useState(Object.keys(skillData)[0]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };


  return (
    <section id="skills" className="py-5 header">
      <div className="container-fluid bg-light my-5 py-6">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.1s">
              <div className="section-title">
                <h2>Skills</h2>
              </div>
              {/* <h3 className="mb-4">My Skills</h3> */}
              <Box>
                <Box>
                  <Tabs
                    variant="scrollable"
                    scrollButtons
                    value={value}
                    onChange={handleChange}
                    aria-label=""
                    sx={{
                      [`& .${tabsClasses.scrollButtons}`]: {
                        "&.Mui-disabled": { opacity: 0.3 },
                      },
                    }}
                  >
                    {Object.keys(skillData).map((key) => (
                      <Tab
                        key={key}
                        value={key}
                        label={skillData[key].title}
                        {...a11yProps(key)}
                      />
                    ))}
                  </Tabs>
                </Box>

                {Object.keys(skillData).map((key, val) => (
                  <>
                    <CustomTabPanel key={key} value={value} index={key}>
                      <div
                        class={`tab-pane fade shadow rounded bg-white show p-5 `}
                        id={`v-pills-${key}`}
                        role="tabpanel"
                        aria-labelledby="pills-home-tab"
                        tabindex="0"
                      >
                        <p className="font-italic text-muted mb-2 p-2">
                          {Object.keys(skillData[key].skills).map(
                            (skillKey, skillval) => (
                              <>
                                {/* <a key={key} href={skillData[key].skills[skillKey].url} target="_blank" rel="noreferrer" className="p-2">
                                  <img src={skillData[key].skills[skillKey].icon} alt="" />
                                </a> */}
                                <div className="d-inline-flex flex-wrap">
                                  <IndividualSkill
                                    item={skillData[key].skills[skillKey]}
                                  ></IndividualSkill>
                                </div>
                              </>
                            )
                          )}
                        </p>
                      </div>
                    </CustomTabPanel>
                  </>
                ))}
              </Box>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Skills;