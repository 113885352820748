import React, { useEffect, useState } from 'react';

const PreLoader = () => {
  const [preloaderExists, setPreloaderExists] = useState(false);

  useEffect(() => {
    const preloader = document.getElementById('preloader');
    if (preloader) {
      setPreloaderExists(true);
    }
  }, []);

  useEffect(() => {
    let timeoutId;
    if (preloaderExists) {
      timeoutId = setTimeout(() => {
        const preloader = document.getElementById('preloader');
        if (preloader) {
          preloader.remove();
        }
      }, 500); 
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [preloaderExists]);

  return (
    <div>
      {/* Your app content */}
        <div id="preloader">
        </div>
    </div>
  );
}

export default PreLoader;
