import education from "../data/education"
import experience from "../data/experience"

const Resume = () => {
  return (
    <section id="resume" className="resume">
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <h2>Resume</h2>
          <a
            href={process.env.REACT_APP_RESUME_PATH}
            className="btn btn-outline-primary rounded-0"
            download
            target="_blank"
          >
            Download Resume
          </a>
        </div>

        <div className="row">
          <div className="col-lg-6" id="education-wrapper">
            <h3 class="resume-title">Education</h3>
            {Object.keys(education).map((key) => (
              <div class="resume-item">
                <h4>{education[key].title}</h4>
                <h5>{education[key].year}</h5>
                <p>
                  <em>{education[key].stream}</em>
                </p>
                <p>{education[key].dept}</p>
              </div>
            ))}
          </div>

          <div className="col-lg-6" id="professional-wrapper">
            <h3 class="resume-title">Professional Experience</h3>
            {Object.keys(experience).map((key) => (
              <div class="resume-item">
                <h4>{experience[key].desig}</h4>
                <h5>{experience[key].period}</h5>
                <p>
                  <em>{experience[key].org}</em>
                </p>
                <ul class="list_items">
                  {experience[key].responsibilities.map((data) => (
                    <li>{data}</li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Resume