let experience = {
  "tech" : {
      "desig" : "Team Lead | Sr.Golang Engineer",
      "period" :"10/09/2020 - Present",
      "org":"Techversantinfo tech - Trivandrum, Kerala.",
      "responsibilities" : [
          "Create an inspiring team environment with an open communication culture. ",
          "Set clear team goals and Motivated the team and solving problems.",
          "Guided the team to complete their tasks.",
          "Organized training and boot camps to maximize the potential of team members.",
          "Maintaining a track record of team progress and documenting the same to make accurate reports.",
          "Handled multiple golang projects which had different tools and technologies such as Docker,Kubernetes, Grafana, Mysql,Postgresl,MongoDB..etc",
          "Implemented CI/CD for a social media application",
      ],
  },

  "epixel" : {
      "desig" : "Senior Web Developer",
      "period" :"24/06/2016 - 30/08/2020",
      "org":"Epixel solutions pvt ltd. Palakkad, Kerala",
      "responsibilities" : [
          "Epixel solution is a leading BlockChain, Mobile and Web Development Company based on Palakkad, kerala ",
          "Faced a lot of challenges regarding real time money and crypto currency transactions and succeeded to solve it. ",
          "Determined coding requirements for site creation, including e-commerce capability and specialized scripts. ",
          "Tested websites and performed troubleshooting prior to deployment.",
          "Research and propose software programs that are compatible with the future technologies. ",
      ],
  }
}


export default experience