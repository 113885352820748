const SocialProfile = ({ item }) => {
  return (
    <>
      {/* <div class="single-profile">
        <div class="profile-txt">
          {item.icon}
          <div class="profile-icon-name">{item.title}</div>
        </div>
        <div class="single-profile-overlay">
          <div class="profile-txt">
            <div class="profile-icon-name">{item.description}</div>
          </div>
        </div>
      </div> */}

      <div className="shadow h-100">
        <div className="box h-100 d-flex flex-column">
          <div className="logo mb-5 text-center pt-2">{item.icon}</div>
          <div className="flex-grow-1 text-secondary">
            <p>{item.description}</p>
          </div>
          <div className="text-center">
            <a
              className="link"
              href={item.link}
              target="_blank"
              rel="noreferrer"
            >
              <span className="tag is-link is-light is-medium">
                <svg
                  className="svg-inline--fa fa-arrow-up-right-from-square m-2"
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fal"
                  data-icon="arrow-up-right-from-square"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  data-fa-i2svg=""
                >
                  <path
                    fill="currentColor"
                    d="M336 0c-8.8 0-16 7.2-16 16s7.2 16 16 16H457.4L212.7 276.7c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0L480 54.6V176c0 8.8 7.2 16 16 16s16-7.2 16-16V16c0-8.8-7.2-16-16-16H336zM64 32C28.7 32 0 60.7 0 96V448c0 35.3 28.7 64 64 64H416c35.3 0 64-28.7 64-64V304c0-8.8-7.2-16-16-16s-16 7.2-16 16V448c0 17.7-14.3 32-32 32H64c-17.7 0-32-14.3-32-32V96c0-17.7 14.3-32 32-32H208c8.8 0 16-7.2 16-16s-7.2-16-16-16H64z"
                  ></path>
                </svg>
                View
              </span>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default SocialProfile;
