import logo from "./logo.svg";
import "./App.css";

import "aos/dist/aos.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";

import "./assets/css/skill.css";
import "./assets/css/style.css";
import "./assets/css/social-profile.css";

import Menu from "./components/Menu";
import Banner from "./components/Banner";
import About from "./components/About";
import Skill from "./components/Skills";
import SkillTab from "./components/SkillsTab";
import SocialProfile from "./components/SocialProfiles";
import Resume from "./components/Resume";
import Contact from "./components/Contact";
import CopyrightNotice from "./components/Copyright";
import PreLoader from "./components/PreLoader";
import OnePage from "./components/Onepage";
import useWindowSize from "./components/UseWindowSize";

function App() {
  const size = useWindowSize();

  return (
    <>
      {/* <OnePage></OnePage> */}
      <Menu></Menu>
      <Banner></Banner>
      <main id="main">
        <About />

        {size.width <= 1000 ? <SkillTab /> : <Skill />}
        
        <SocialProfile></SocialProfile>
        <Resume />
        <Contact />
      </main>

      <PreLoader />

      <footer id="footer">
        <div class="container">
          <h3>PRATHEESH PC</h3>
          <div class="social-links">
            <a href="https://www.instagram.com/pcpratheesh/" class="instagram">
              <i class="bx bxl-instagram"></i>
            </a>
            <a
              href="https://www.linkedin.com/in/pratheesh-pc/"
              class="linkedin"
            >
              <i class="bx bxl-linkedin"></i>
            </a>
            <a href="https://www.github.com/pcpratheesh/" class="github">
              <i class="bx bxl-github"></i>
            </a>
          </div>
          <CopyrightNotice />
        </div>
      </footer>
    </>
  );
}

export default App;
