import ReactPageScroller, { SectionContainer } from "react-page-scroller"
// import { Pager } from "react-bootstrap";
import { useState } from "react";
import Menu from "./Menu";
import Banner from "./Banner";
import About from "./About";
import Skill from "./Skills";
import CopyrightNotice from "./Copyright";
import PreLoader from "./PreLoader";
import Contact from "./Contact";
import Resume from "./Resume";
import SocialProfile from "./SocialProfiles";

const OnePage = () => {
  const [currentPage, setCurrentPage] = useState(0)
  const handlePageChange = number => {
    console.log(number)
    setCurrentPage(number)
  };

  const handleBeforePageChange = number => {
    console.log(number);
  };

  // const getPagesNumbers = () => {
  //   const pageNumbers = [];

  //   for (let i = 1; i <= 5; i++) {
  //     pageNumbers.push(
  //       <Pager.Item key={i} eventKey={i - 1} onSelect={this.handlePageChange}>
  //         {i}
  //       </Pager.Item>,
  //     );
  //   }

  //   return [...pageNumbers];
  // };

  // const pagesNumbers = getPagesNumbers();

  return (
    <>
      <Menu></Menu>
      <ReactPageScroller
        pageOnChange={handlePageChange}
        onBeforePageScroll={handleBeforePageChange}
        customPageNumber={currentPage}
      >
        <div>
          <Banner></Banner>
        </div>
        <main className="main">
          <About></About>
        </main>
        <main className="main">
          <Skill />
        </main>
        <main className="main">
          <SocialProfile></SocialProfile>
        </main>
        <main className="main">
          <Resume />
        </main>
        <main className="main">
          <Contact />
        </main>

        <PreLoader />
        <footer id="footer">
          <div class="container">
            <h3>PRATHEESH PC</h3>
            <div class="social-links">
              <a href="https://www.instagram.com/pcpratheesh/" class="instagram"><i class="bx bxl-instagram"></i></a>
              <a href="https://www.linkedin.com/in/pratheesh-pc/" class="linkedin"><i class="bx bxl-linkedin"></i></a>
              <a href="https://www.github.com/pcpratheesh/" class="github"><i class="bx bxl-github"></i></a>
            </div>
            <CopyrightNotice />
          </div>
        </footer>
      </ReactPageScroller>
      {/* <Pager className="pagination-additional-class" bsSize="large">
        {pagesNumbers}
      </Pager> */}
    </>
  )
}

export default OnePage