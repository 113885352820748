import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBootstrap,
  faCss3,
  faGolang,
  faHtml5,
  faJs,
  faNode,
  faNodeJs,
  faPhp,
  faPython,
  faReact,
  faVuejs,
  faDocker,
  faAws,
  faJenkins,
  faMicrosoft,
  faBitbucket
} from "@fortawesome/free-brands-svg-icons";

import Graphql from "./brands/graphql.svg";
import Kafka from "./brands/kafka.png";
import RabbitMQ from "./brands/rabbitmq.svg";
import Nginx from "./brands/nginx.svg";
import Mongo from "./brands/db/mongo.svg";
import MySql from "./brands/db/mysql.svg";
import Dynamo from "./brands/db/dynamodb.svg";
import Es from "./brands/db/es.png";
import Firebase from "./brands/db/firebase.svg";
import Influx from "./brands/db/influx.png";
import Psql from "./brands/db/psql.svg";
import Redis from "./brands/db/redis.svg";
import SqlLite from "./brands/db/sqllite.svg";
import Apache from "./brands/apache.svg";

// devops
import Kubs from "./brands/devop/kub.png";
import Bash from "./brands/devop/terminal-bash.svg";
import Gitlab from "./brands/devop/gitlab.svg";
import CircleCI from "./brands/devop/circle-ci.svg";
import Grafana from "./brands/devop/grafana.svg";
import Heroku from "./brands/devop/heroku.svg";

// 
import Django from "./brands/framework/django.svg";
import Drupal from "./brands/framework/drupal.svg";
import Figma from "./brands/framework/figma.svg";
import Hugo from "./brands/framework/hugo.svg";
import Insomnia from "./brands/framework/insomnia.svg";
import Postman from "./brands/framework/postman.svg";
import Photoshop from "./brands/framework/ps.svg";
import JWT from "./brands/framework/jwt.svg";
import Codeigniter from "./brands/framework/codeigniter.svg";
import Laravel from "./brands/framework/laravel.svg";

// other
import Git from "./brands/other/git.svg";
import Github from "./brands/other/github.svg";
import Jira from "./brands/other/jira.svg";
import Bitbucket from "./brands/other/bitbucket.svg";
import Slack from "./brands/other/slack.svg";
import MSTeams from "./brands/other/teams.svg";
import Ubuntu from "./brands/other/ubuntu.svg";


const Skills = {
  languages: {
    image:
      "https://img.shields.io/badge/languages--%23cccc.svg?&style=for-the-badge&logoColor=white",
    title: "Languages",
    skills: [
      {
        title: "golang",
        icon: "https://img.shields.io/badge/golang-%236ad7e5.svg?&style=for-the-badge&logo=go&logoColor=white",
        url: "https://golang.org",
        font_icon: <FontAwesomeIcon icon={faGolang} />,
        color: "blue",
      },
      {
        title: "php",
        url: "https://www.php.net",
        icon: "https://img.shields.io/badge/php-%232f60b3.svg?&style=for-the-badge&logo=php&logoColor=white",
        font_icon: <FontAwesomeIcon icon={faPhp} size="lg" />,
        color: "blue",
      },
      {
        title: "python",
        url: "https://www.python.org",
        icon: "https://img.shields.io/badge/python-%233772a3.svg?&style=for-the-badge&logo=python&logoColor=white",
        font_icon: <FontAwesomeIcon icon={faPython} />,
        color: "blue-dark",
      },
      {
        title: "nodejs",
        url: "https://nodejs.org/",
        icon: "https://img.shields.io/badge/nodejs-%235c9954.svg?&style=for-the-badge&logo=node.js&logoColor=white",
        font_icon: <FontAwesomeIcon icon={faNode} />,
        color: "green",
      },
    ],
  },

  "front-end": {
    image:
      "https://img.shields.io/badge/Frontend Development--%23cccc.svg?&style=for-the-badge&logoColor=white",
    title: "Frontend Development",
    skills: [
      {
        title: "bootstrap",
        icon: "https://img.shields.io/badge/bootstrap-%235b4282.svg?&style=for-the-badge&logo=bootstrap&logoColor=white",
        url: "https://getbootstrap.com",
        font_icon: <FontAwesomeIcon icon={faBootstrap} />,
        color: "purple",
      },

      {
        title: "vuejs",
        icon: "https://img.shields.io/badge/vue-%2348b883.svg?&style=for-the-badge&logo=vuetify&logoColor=white",
        url: "https://vuejs.org/",
        font_icon: <FontAwesomeIcon icon={faVuejs} />,
        color: "green-dark",
      },

      {
        title: "react",
        url: "https://reactjs.org/",
        icon: "https://img.shields.io/badge/react-%235fd8f3.svg?&style=for-the-badge&logo=react&logoColor=black",
        font_icon: <FontAwesomeIcon icon={faReact} />,
        color: "blue",
      },
      {
        title: "css",
        url: "https://www.w3schools.com/css/",
        icon: "https://img.shields.io/badge/css-%231572b6.svg?&style=for-the-badge&logo=css3&logoColor=white",
        font_icon: <FontAwesomeIcon icon={faCss3} />,
        color: "blue",
      },
      {
        title: "html5",
        url: "https://www.w3.org/html/",
        icon: "https://img.shields.io/badge/html5-%23e44e32.svg?&style=for-the-badge&logo=html5&logoColor=white",
        font_icon: <FontAwesomeIcon icon={faHtml5} />,
        color: "red",
      },
      {
        title: "JavaScript",
        url: "https://developer.mozilla.org/en-US/docs/Web/JavaScript",
        icon: "https://img.shields.io/badge/js-%23f0db4f.svg?&style=for-the-badge&logo=javascript&logoColor=black",
        font_icon: <FontAwesomeIcon icon={faJs} />,
        color: "yellow",
      },
    ],
  },

  backend: {
    image:
      "https://img.shields.io/badge/Backend Development--%23cccc.svg?&style=for-the-badge",
    title: "Backend Development",
    skills: [
      {
        title: "GraphQl",
        url: "https://graphql.org",
        icon: "https://img.shields.io/badge/graphql-%23e45899.svg?&style=for-the-badge&logo=graphql&logoColor=black",
        font_icon: <img src={Graphql} alt="" />,
        color: "",
        style: {
          background:
            "linear-gradient(to bottom right, #f004 0%, #E10098 100%)",
          color: "#E10098",
        },
      },
      {
        title: "Kafka",
        url: "https://kafka.apache.org/",
        icon: "https://img.shields.io/badge/kafka-%23000000.svg?&style=for-the-badge&logo=apachekafka&logoColor=white",
        font_icon: <img src={Kafka} alt="" />,
        style: {
          background:
            "linear-gradient(to bottom right, #251b1d00 0%, #000 100%)",
          color: "#000",
        },
      },
      {
        title: "RabbitMQ",
        url: "https://www.rabbitmq.com",
        icon: "https://img.shields.io/badge/rabbitmq-%23fa6838.svg?&style=for-the-badge&logo=rabbitmq&logoColor=black",
        font_icon: <img src={RabbitMQ} alt="" />,
        style: {
          background:
            "linear-gradient(to bottom right, #ff6600 0%, #ff1500 100%)",
          color: "#ff6600",
        },
      },
      {
        title: "Nginx",
        url: "https://www.nginx.com",
        icon: "https://img.shields.io/badge/nginx-%23479a12.svg?&style=for-the-badge&logo=nginx&logoColor=black",
        font_icon: <img src={Nginx} alt="" />,
      },
      {
        title: "Apache2",
        url: "https://httpd.apache.org/",
        icon: "https://img.shields.io/badge/nginx-%23479a12.svg?&style=for-the-badge&logo=nginx&logoColor=black",
        font_icon: <img src={Apache} alt="" />,
      },
    ],
  },

  mobile_app: {
    image:
      "https://img.shields.io/badge/Mobile App Development--%23cccc.svg?&style=for-the-badge",
    title: "Mobile App Development",
    skills: [
      {
        title: "react-native",
        url: "https://reactnative.dev/",
        icon: "https://img.shields.io/badge/native-%2361dafb.svg?&style=for-the-badge&logo=react&logoColor=black",
        font_icon: <FontAwesomeIcon icon={faReact} />,
        color: "blue",
      },
    ],
  },

  databases: {
    image:
      "https://img.shields.io/badge/Databases--%23cccc.svg?&style=for-the-badge",
    title: "Databases",
    skills: [
      {
        title: "MongoDB",
        url: "https://www.mongodb.com/",
        icon: "https://img.shields.io/badge/mongodb-%234bad56.svg?&style=for-the-badge&logo=mongodb&logoColor=black",
        font_icon: <img src={Mongo} alt="" />,
        color: "green-dark",
      },
      {
        title: "MySQL",
        url: "https://www.mysql.com/",
        icon: "https://img.shields.io/badge/mysql-%2317737c.svg?&style=for-the-badge&logo=mysql&logoColor=black",
        font_icon: <img src={MySql} alt="" />,
      },
      {
        title: "PostgreSQl",
        url: "https://www.postgresql.org",
        icon: "https://img.shields.io/badge/postgresql-%23336791.svg?&style=for-the-badge&logo=postgresql&logoColor=black",
        font_icon: <img src={Psql} alt="" />,
      },
      {
        title: "InfluxDB",
        url: "https://www.influxdata.com/",
        icon: "https://img.shields.io/badge/InfluxDB-%23047dd5.svg?&style=for-the-badge&logo=InfluxDB&logoColor=black",
        font_icon: <img src={Influx} alt="" />,
      },
      {
        title: "redis",
        url: "https://redis.io",
        icon: "https://img.shields.io/badge/redis-%23d8362f.svg?&style=for-the-badge&logo=redis&logoColor=black",
        font_icon: <img src={Redis} alt="" />,
      },
      {
        title: "Elastic Search",
        url: "https://www.elastic.co",
        icon: "https://img.shields.io/badge/ES-%23f05a98.svg?&style=for-the-badge&logo=elasticsearch&logoColor=black",
        font_icon: <img src={Es} alt="" />,
      },
      {
        title: "SQL Lite",
        url: "https://www.sqlite.org/index.html",
        icon: "https://img.shields.io/badge/sqlite-%2307405e.svg?style=for-the-badge&logo=sqlite&logoColor=white",
        font_icon: <img src={SqlLite} alt="" />,
      },
      {
        title: "Dynamo DB",
        url: "https://aws.amazon.com/dynamodb/",
        icon: "https://img.shields.io/badge/DynamoDB-4053D6?style=for-the-badge&logo=Amazon%20DynamoDB&logoColor=white",
        font_icon: <img src={Dynamo} alt="" />,
      },
      {
        title: "Firebase",
        url: "https://firebase.google.com/",
        icon: "https://img.shields.io/badge/Firebase-039BE5?style=for-the-badge&logo=Firebase&logoColor=white",
        font_icon: <img src={Firebase} alt="" />,
      },
    ],
  },

  visualization: {
    image:
      "https://img.shields.io/badge/Data Visualization--%23cccc.svg?&style=for-the-badge",
    title: "Data Visualization",
    skills: [
      {
        title: "Grafana",
        url: "https://grafana.com",
        font_icon: <img src={Grafana} alt="" />,
      },
    ],
  },

  devops: {
    image:
      "https://img.shields.io/badge/Devops--%23cccc.svg?&style=for-the-badge",
    title: "DevOps",
    skills: [
      {
        title: "Docker",
        url: "https://www.docker.com/",
        font_icon: <FontAwesomeIcon icon={faDocker} />,
        color: "blue",
      },
      {
        title: "AWS",
        url: "https://aws.amazon.com",
        font_icon: <FontAwesomeIcon icon={faAws} />,
        color: "black",
      },
      {
        title: "Microsoft",
        url: "https://azure.microsoft.com/en-in/",
        font_icon: <FontAwesomeIcon icon={faMicrosoft} />,
        color: "yellow",
      },
      {
        title: "Kubernetes",
        url: "https://kubernetes.io",
        font_icon: <img src={Kubs} alt="" />,
        color: "blue",
      },
      {
        title: "Jenkins",
        url: "https://www.jenkins.io",
        font_icon: <FontAwesomeIcon icon={faJenkins} />,
        color: "red",
      },
      {
        title: "Bash",
        url: "https://www.gnu.org/software/bash/",
        font_icon: <img src={Bash} alt="" />,
      },
      {
        title: "Gitlab CI",
        url: "https://docs.gitlab.com/ee/ci/",
        icon: "https://img.shields.io/badge/gitlab%20ci-%23346ee5.svg?style=for-the-badge&logo=gitlab&logoColor=white",
        font_icon: <img src={Gitlab} alt="" />,
      },
      {
        title: "Circle CI",
        url: "https://circleci.com/",
        icon: "https://img.shields.io/badge/circle%20ci-%23161616.svg?style=for-the-badge&logo=circleci&logoColor=white",
        font_icon: <img src={CircleCI} alt="" />,
      },
    ],
  },

  baas: {
    image:
      "https://img.shields.io/badge/Backend as a Service(BaaS)--%23cccc.svg?&style=for-the-badge",
    title: "Backend as a Service(BaaS)",
    skills: [
      {
        title: "Heroku",
        url: "https://heroku.com",
        font_icon: <img src={Heroku} alt="" />,
      },
    ],
  },

  "frame-works": {
    image:
      "https://img.shields.io/badge/Framework--%23cccc.svg?&style=for-the-badge",
    title: "Framework",
    skills: [
      {
        title: "codeigniter",
        url: "https://codeigniter.com",
        font_icon: <img src={Codeigniter} alt="" />,
      },
      {
        title: "Laravel",
        url: "https://laravel.com/",
        font_icon: <img src={Laravel} alt="" />,
      },
      {
        title: "Django",
        url: "https://www.djangoproject.com/",
        font_icon: <img src={Django} alt="" />,
        color: "dark-green",
      },
      {
        title: "Drupal",
        url: "https://www.drupal.org/",
        font_icon: <img src={Drupal} alt="" />,
      },
      {
        title: "Hugo",
        url: "https://gohugo.io/",
        font_icon: <img src={Hugo} alt="" />,
      },
      {
        title: "JWT",
        url: "https://jwt.io/",
        font_icon: <img src={JWT} alt="" />,
      },
    ],
  },

  sw: {
    image:
      "https://img.shields.io/badge/Software--%23cccc.svg?&style=for-the-badge",
    title: "Software",
    skills: [
      {
        title: "figma",
        url: "https://www.figma.com/",
        font_icon: <img src={Figma} alt="" />,
      },
      {
        title: "photoshop",
        url: "https://www.photoshop.com/en",
        font_icon: <img src={Photoshop} alt="" />,
      },
      {
        title: "postman",
        url: "https://postman.com",
        font_icon: <img src={Postman} alt="" />,
      },
      {
        title: "insomnia",
        url: "https://insomnia.com",
        font_icon: <img src={Insomnia} alt="" />,
      },
    ],
  },

  other: {
    image:
      "https://img.shields.io/badge/Other--%23cccc.svg?&style=for-the-badge",
    title: "Other",
    skills: [
      {
        title: "git",
        url: "https://git-scm.com/",
        font_icon: <img src={Git} alt="" />,
      },
      {
        title: "github",
        url: "https://github.com/",
        font_icon: <img src={Github} alt="" />,
      },
      {
        title: "bitbucket",
        url: "https://bitbucket.com/",
        font_icon: <img src={Bitbucket} alt="" />,
      },
      {
        title: "gitlab",
        url: "https://gitlab.com/",
        font_icon: <img src={Gitlab} alt="" />,
      },
      {
        title: "jira",
        url: "https://www.atlassian.com/software/jira",
        font_icon: <img src={Jira} alt="" />,
      },
      {
        title: "slack",
        url: "https://slack.com/",
        font_icon: <img src={Slack} alt="" />,
      },
      {
        title: "Ubuntu",
        url: "https://ubuntu.com/",
        font_icon: <img src={Ubuntu} alt="" />,
      },
    ],
  },
};

export default Skills;
