import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhp } from "@fortawesome/free-brands-svg-icons";
import Button from "@mui/material/Button";
import { IconButton } from "@mui/material";

const IndividualSkill = ({ item }) => {
  return (
    <>
      {/* <div class="badge-main-wrapper">
        <div class={`badge ${item.color}`} style={item.style}>
          <div class="circle">
            {item.font_icon}
          </div>
          <div class="ribbon">{item.title}</div>

        </div>
        </div> */}

      {/* <IconButton color="secondary" style={{ borderRadius: 50 }}>
        {item.font_icon} {item.title}
      </IconButton>

      <Button
        component="label"
        role={undefined}
        variant="contained"
        tabIndex={-1}
        startIcon={item.font_icon}
        style={{ borderRadius: 50 }}
      >
        {item.title}
      </Button> */}

        <a
          href="/"
          className={`chip-tile m-2`}
        >
          <div className={`chip-tile-icon-wrapper ${item.color}`}>{item.font_icon}</div>
          <span className={`chip-tile-text tbody-5`}>{item.title}</span>
        </a>
    </>
  );
};

export default IndividualSkill;
