import SocialProfiles from "../data/social-profile"
import Item from './partials/social-profile'

const SocialProfile = () => {
  return (
    <section id="social-profiles" className="py-5 header bg-light-blue">
      <div className="container-fluid  my-5 py-6" >
        <div className="container">

          <div className="section-title">
            <h2>Social</h2>
          </div>
          <div className="row g-4">
            {
              SocialProfiles.map((item, index) => (
                // <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s" key={index}>
                //   <div className="service-item d-flex flex-column flex-sm-row bg-white rounded h-100 p-4 p-lg-5">
                //     <div className="bg-icon flex-shrink-0 mb-3 d-flex justify-content-center align-items-center">
                //       {item.icon}
                //     </div>
                //     <div className="ms-sm-4">
                //       <h4 className="mb-3">{item.title}</h4>
                //       <span>{item.description}</span>
                //       <p>{item.link}</p>
                //     </div>
                //   </div>
                // </div>
                <div className="col-lg-4 wow fadeInUp" data-wow-delay="0.1s" key={index}>
                  <Item item={item}></Item>
                </div>
              ))
            }

          </div>
        </div>
      </div>
    </section>
    // <div className="container-fluid bg-light my-5 py-6" id="social">
    //     <div className="container">
    //         <div className="row g-5 mb-5 wow fadeInUp" data-wow-delay="0.1s">
    //             <div className="col-lg-6">
    //                 <h1 className="display-5 mb-0">Social profiles</h1>
    //             </div>
    //         </div>
    //         <div className="row g-4">
    //             {
    //                 SocialProfiles.map((item, index) => (
    //                     <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s" key={index}>
    //                         <div className="service-item d-flex flex-column flex-sm-row bg-white rounded h-100 p-4 p-lg-5">
    //                             <div className="bg-icon flex-shrink-0 mb-3">
    //                                 {item.icon}
    //                             </div>
    //                             <div className="ms-sm-4">
    //                                 <h4 className="mb-3">{item.title}</h4>
    //                                 <span>{item.description}</span>
    //                                 <p>{item.link}</p>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 ))
    //             }
    //         </div>
    //     </div>
    // </div>
  )
}

export default SocialProfile;