import { useEffect } from "react";
import AOS from "aos";
import { ReactTyped } from "react-typed";

const Banner = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
      once: true,
      mirror: false,
    });
  });
  return (
    <>
      <section id="hero" className="d-flex flex-column justify-content-center">
        <div className="container" data-aos="zoom-in" data-aos-delay="100">
          <h1>PRATHEESH PC</h1>
          <p>
            I'm &nbsp;
            <ReactTyped
              className="typed"
              strings={[
                "Team Lead",
                "Developer",
                "Tech Enthusiast",
                "Full-stack",
              ]}
              typeSpeed={40}
              backSpeed={50}
              loop
            />
          </p>
          <div className="social-links-hidden social-links-outer">
            {/* <a
              href="https://www.buymeacoffee.com/pcpratheesh"
              className="instagram"
            >
              <i class="bx bxs-coffee-alt"></i>
            </a>
            <a
              href="https://www.linkedin.com/in/pratheesh-pc/"
              className="linkedin"
            >
              <i className="bx bxl-linkedin"></i>
            </a>
            <a href="https://www.github.com/pcpratheesh/" className="github">
              <i className="bx bxl-github"></i>
            </a> */}

            <ul class="social-links-wrapper px-0 py-4">
              <li className="icon-content">
                <a
                  href="https://www.linkedin.com/in/pratheesh-pc/"
                  aria-label="Linkedin"
                  data-social="linkedin"
                  className="icon"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="filled"></div>
                  <i className="bx bxl-linkedin"></i>
                </a>
              </li>
              <li className="icon-content">
                <a
                  href="https://www.github.com/pcpratheesh/"
                  aria-label="Github"
                  data-social="github"
                  className="icon"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="filled"></div>
                  <i className="bx bxl-github"></i>
                </a>
              </li>
              <li className="icon-content">
                <a
                  href="https://www.buymeacoffee.com/pcpratheesh"
                  aria-label="BuyMeaCoffe"
                  data-social="buyMeaCoffe"
                  className="icon"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="filled"></div>
                  <i class="bx bxs-coffee-alt"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

export default Banner;
