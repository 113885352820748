let education = {
  "bca" : {
      "title" : "Bachelor of Computer Applications(BCA)",
      "year":"2017 - 2019",
      "stream":"Distance Education",
      "dept":"Bharathiar University",
  },

  "diploma" : {
      "title" : "Diploma in Computer engineering",
      "year":"2013 - 2016",
      "stream":"Board of Technical Education, Kerala",
      "dept":"AKNM GPTC Thirurangadi",
  },
}


export default education