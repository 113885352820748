const About = () => {
  return (
    <>
      <section id="about" className="about bg-light-blue">
        <div className="container" data-aos="fade-up">

          <div className="section-title">
            <h2>About</h2>

          </div>

          <div className="row">
            <div className="col-lg-4 text-center">
              <img src="assets/img/profile-img.jpg" className="img-fluid" alt="" style={{ width: "70%" }} />
            </div>
            <div className="col-lg-8 pt-4 pt-lg-0 content">
              <p>Hi there, my name is Pratheesh, and I'm a passionate software developer with a deep love for coding and building amazing things with technology. My journey in the world of software development began at 2016, tinkering with computers and teaching myself how to code. Since then, I've honed my skills and become a proficient programmer, with expertise in several programming languages, including Python, JavaScript, PHP and Golang.</p>
              <p>When I'm not writing code, you can usually find me reading up on the latest tech trends, or sharing my knowledge and expertise with others through blogging and mentoring. I believe that technology has the power to transform lives and make the world a better place, and I'm dedicated to using my skills to make a positive impact in the world.</p>

              <p>I am passionate about accepting challenges and ready to handle the hard situation and manage them very easily.Willing to jump any issue and able to develop "outside the box" solutions.</p>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default About