import { useEffect } from "react";
import { useState } from "react";

const Menu = () => {

  const [navbarLinks, setNavbarLinks] = useState([]);

  useEffect(() => {
    const handleScroll = () => {
      let position = window.scrollY + 200;
      navbarLinks.forEach(navbarlink => {
        if (!navbarlink.hash) return;
        let section = document.querySelector(navbarlink.hash);
        if (!section) return;
        if (position >= section.offsetTop && position <= (section.offsetTop + section.offsetHeight)) {
          navbarlink.classList.add('active')
        } else {
          navbarlink.classList.remove('active')
        }
      });
    };

    // Add event listener to handle scrolling
    window.addEventListener('scroll', handleScroll);
    
    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [navbarLinks]);

  useEffect(() => {
    // Select navbar links when component mounts
    const navbarlinks = document.querySelectorAll('#navbar .scrollto');
    setNavbarLinks(Array.from(navbarlinks));
  }, []);
  
  return (
    <>
      <i className="bi bi-list mobile-nav-toggle d-lg-none"></i>
      <header id="header" className="d-flex flex-column justify-content-center">

        <nav id="navbar" className="navbar nav-menu">
          <ul>
            <li><a href="#hero" className="nav-link scrollto active"><i className="bx bx-home"></i> <span>Home</span></a></li>
            <li><a href="#about" className="nav-link scrollto"><i className="bx bx-user"></i> <span>About</span></a></li>
            <li><a href="#skills" className="nav-link scrollto"><i className="bx bx-poll"></i> <span>Skills</span></a></li>
            <li><a href="#social-profiles" className="nav-link scrollto"><i className="bx bx-conversation"></i> <span>Social Profiles</span></a></li>
            <li><a href="#resume" className="nav-link scrollto"><i className="bx bx-file-blank"></i> <span>Resume</span></a></li>
            <li><a href="#contact" className="nav-link scrollto"><i className="bx bx-envelope"></i> <span>Contact</span></a></li>
          </ul>
        </nav>

      </header>
    </>
  )
}

export default Menu