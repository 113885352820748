import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub,faStackOverflow, faMedium,faLinkedinIn, faHackerrank, } from '@fortawesome/free-brands-svg-icons'
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';

const SocialProfiles = [
  {
    title: "GITHUB",
    icon: (
      <FontAwesomeIcon icon={faGithub} style={{ color: "#000" }} size="2xl" />
    ),
    description:
      "GitHub serves as an excellent platform to demonstrate my expertise in various programming languages and technologies.Added projects to showcase my programming knowledge and skills",
    // "link" : <Link target="_blank" rel="noopener" href="https://github.com/pcpratheesh" ><Button>View</Button></Link>
    link: "https://github.com/pcpratheesh",
  },
  {
    title: "STACK OVERFLOW",
    icon: (
      <FontAwesomeIcon
        icon={faStackOverflow}
        style={{ color: "#F48024" }}
        size="2xl"
      />
    ),
    description:
      "I use Stack Overflow for answering questions related to programming and software development. As an experienced developer, I find it rewarding to share my knowledge and expertise with others in the community",
    link: "https://stackoverflow.com/users/21731712/pratheesh-pc",
  },
  {
    title: "MEDIUM",
    icon: (
      <FontAwesomeIcon icon={faMedium} style={{ color: "green" }} size="2xl" />
    ),
    description:
      "Used Medium as a platform to share my knowledge and expertise in various topics related to programming and technology.",
    link: "https://pcpratheesh.medium.com/",
  },
  {
    title: "LINKEDIN",
    icon: (
      <FontAwesomeIcon
        icon={faLinkedinIn}
        style={{ color: "#0077b5" }}
        size="2xl"
      />
    ),
    description:
      "A valuable tool for expanding your professional network, showcasing my skills and experience, and finding new job opportunities",
    link: "https://www.linkedin.com/in/pratheesh-pc/",
  },
  {
    title: "TECHGIG",
    icon: <img src="assets/img/techgig.png" alt="" width={"100px"} />,
    description:
      "A comprehensive platform to sharpen my technical skills, stay updated on industry trends, connect with peers, and advance my career prospects",
    link: "https://www.techgig.com/pub/pratheeshpc/",
  },
  {
    title: "HACKER RANK",
    icon: (
      <FontAwesomeIcon
        icon={faHackerrank}
        style={{ color: "green" }}
        size="2xl"
      />
    ),
    description:
      "Opted for HackerRank due to its extensive array of coding challenges, robust community support, effective skill enhancement resources, and engaging competitive programming platform",
    link: "https://www.hackerrank.com/profile/pcpratheesh",
  },
  // {
  //     "title" : "WEB",
  //     "icon" : <FontAwesomeIcon icon={faBlog} beat style={{color: "blue",}} size="2xl"/>,
  //     "link":"coming soon"
  // }
];

export default SocialProfiles;