import * as React from "react";

import { Box } from "@mui/material";
import data from "../data/skills";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Content from "./SkillContent";
import IndividualSkill from "./partials/skill";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Skill = () => {
  const [value, setValue] = React.useState(Object.keys(data)[0]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <section id="skills" className="py-5 header">
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <h2>Skills</h2>
        </div>

        <div className="row">
          <div className="col-md-3 shadow">
            <Tabs
              orientation="vertical"
              variant="scrollable"
              scrollButtons
              value={value}
              onChange={handleChange}
              aria-label=""
              sx={{
                [`& .${tabsClasses.scrollButtons}`]: {
                  "&.Mui-disabled": { opacity: 0.3 },
                },
              }}
            >
              {Object.keys(data).map((key) => (
                <Tab
                  key={key}
                  value={key}
                  label={data[key].title}
                  {...a11yProps(key)}
                />
              ))}
            </Tabs>
          </div>
          <div className="col-md-9 ">
            <div className="tab-content shadow h-100" id="skills-tab-content">
              {Object.keys(data).map((key, val) => (
                <>
                  <CustomTabPanel key={key} value={value} index={key}>
                    <div
                      className={`tab-pane fade rounded bg-white show p-5 `}
                      id={`v-pills-${key}`}
                      role="tabpanel"
                      aria-labelledby="pills-home-tab"
                      tabIndex="0"
                    >
                      {Object.keys(data[key].skills).map(
                        (skillKey, skillval) => (
                          <>
                            <div className="d-inline-flex flex-wrap">
                              <IndividualSkill
                                item={data[key].skills[skillKey]}
                              ></IndividualSkill>
                            </div>
                            {/* <p className="font-italic text-muted mb-2 p-2">
                              <a key={key} href={data[key].skills[skillKey].url} target="_blank" rel="noreferrer" className="p-2">
                                <img src={data[key].skills[skillKey].icon} alt="" />
                              </a>
                              </p> */}
                          </>
                        )
                      )}
                    </div>
                  </CustomTabPanel>
                </>
              ))}
              {/* <Content></Content> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Skill;
